<template>
	<div />
</template>

<script async>
import { mapGetters } from 'vuex'

export default {
	data() {
		return {
			hubSpotLoaded: false
		}
	},
	computed: {
		...mapGetters('auth', [ 'loggedIn', 'user', 'auth' ]),
		shouldSetHubspotUser () { return this.hubSpotLoaded && this.auth && this.auth.loggedIn }
	},
	watch: {
		shouldSetHubspotUser() {
			if (this.shouldSetHubspotUser === true) {
				this.setHubspotUser()
			}
		}
	},
	mounted() {
		this.loadHubspotPlugin()
	},
	methods: {
		loadHubspotPlugin() {
			const pluginScript = document.createElement('script')
			pluginScript.onload = () => {
				this.loadHubspotScript()
			}
			pluginScript.setAttribute('src', 'https://ipmeta.io/plugin.js')
			document.body.appendChild(pluginScript)
		},
		loadHubspotScript() {
			const pluginScript = document.createElement('script')
			pluginScript.onload = () => {
				this.hubSpotLoaded = true
			}
			pluginScript.setAttribute('src', 'https://js.hs-scripts.com/7545422.js')
			document.body.appendChild(pluginScript)
		},
		setHubspotUser() {
			const _hsq = window._hsq = window._hsq || []
			if (!_hsq?.push) return
			_hsq.push([ 'identify', {
				email: this.auth.email,
				id: this.auth.id
			} ])
		}
	}
}
</script>

<style scoped>
#hubspot-messages-iframe-container {
	z-index: 10 !important;
}
</style>
